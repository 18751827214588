@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixins";

.actualities{
    &__item{
        @include flex();
        @media (max-width: 495px){
            flex-direction: column;
        }
    }
    &__img{
        width: 150px;
        height: 150px;
        overflow: hidden;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        @media (max-width: 495px){
            width: 100%;
            border-radius: 10px 10px 0 0;
        }
    }
    &__box{
        width: calc(100% - 150px);
        border: 2px solid #787878;
        padding: 20px 30px;
        height: 320px;
        border-radius: 0 10px 10px 10px;
        small{
            font-family: 'Krona One', sans-serif;
            color:$colorGreen;
        }
        h4{
            font-weight: bold;
            margin-top:13px;
            margin-bottom:20px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        p{
            font-weight: 400;
            line-height: 18px;
            margin-bottom:30px;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        @media (max-width: 767px){
            padding-left: 10px;
            padding-right: 10px;
        }
        @media (max-width: 495px){
            width: 100%;
            border-radius: 0 0 10px 10px;
        }
    }
}