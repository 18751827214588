@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.header{
    background-color: $colorWhite;
    &.active{
        box-shadow: 0px 15px 18px 0px #0000000d;
        position: fixed;
        top:0;
        left:0;
        width: 100%;
    }
    //
    z-index: 6;
    &__bar{
        position: absolute;
        bottom:0;
        left:0;
        width: 100%;
        height: 5px;
        &--progress{
            display: block;
            position: absolute;
            width: 0%;
            height: 5px;
            background: linear-gradient(181deg, #BEF3C3 -12.05%, #1FB69B 47.5%, #004337 98.96%);
        }
    }
    &__content{
        padding-top: 10px;
        padding-bottom: 10px;
        @include flex();
        @include flexCenterVertical($align:center);
        @include flexCenterHorizontal($align:space-between);

        .header__menuBtn {
            display: block;
            width: 30px;
            height: 30px;
            transition-duration: 0.5s;
            cursor:pointer;
            display: none;
            margin-top: 3px;
            &__line{
                transition-duration: 0.5s;
                position: absolute;
                height: 4px;
                width: 30px;
                top: 13px;
                background-color: #212121;
                &:before {
                    transition-duration: 0.5s;
                    position: absolute;
                    width: 30px;
                    height: 4px;
                    background-color: #212121;
                    content: "";
                    top: -10px;
                }
                &:after {
                    transition-duration: 0.5s;
                    position: absolute;
                    width: 30px;
                    height: 4px;
                    background-color: #212121;
                    content: "";
                    top: 10px;
                }
            }
            &.open {
                .header__menuBtn__line {
                  transition: 0.5s;
                  
                  &:before{
                    transform: rotateZ(-45deg) scaleX(0.75) translate(-10px, -3px);
                  }
            
                  &:after{
                    transform: rotateZ(45deg) scaleX(0.75) translate(-10px, 3px);
                  }
                }
            }
            @media (max-width: 1382px){
                display: block;
            }
        }
    }
    &__logo{
        display: inline-block;
        width:158px;
    }
    &-menu{

        &-list{
            @include flex();
            gap:30px;
            &__item{
                &.o-link{
                    display: none;
                }
                @media (max-width:1750px){
                    &.o-link-plus{
                        display: none;
                    }
                }
                a{
                    display:block;
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: -0.36px;
                    color:$colorPrimaryBack;
                    transition: .5s;
                    white-space: nowrap;
                    &:hover{
                        color:$colorGreen;
                    }
                    &.active{
                        color:$colorGreen;
                        font-weight: 700;
                    }
                }
            }
        }
        &-action{
            display: none;
            @include flexWrap($wrap:nowrap);
            gap:20px;
            &__auth{
                display: inline-block;
                border-radius: 8px;
                border: 2px solid $colorGreen;
                white-space: nowrap;
                text-align: center;
                padding:0 10px;
                @include flex();
                @include flexCenterVertical($align:center);
                transition: .5s;
                &.login{
                    @include flexCenterHorizontal();
                    gap:5px;
                    padding-right: 25px;
                    //border-radius: 23px;
                    img{
                        width: 35px;
                    }
                }
                &.register{
                    @include flexCenterHorizontal();
                    padding:0 43px;
                }
                &:hover{
                    background-color: $colorGreen;
                    color: $colorWhite;
                }
                @media (max-width: 1382px){
                    //display: none;
                }
            }
            @media (max-width: 767px){
                margin-top: 15px;
                @include flex();
            }
        }
        @media (max-width: 1382px){
            max-height: calc(100vh - 80px);
            position:absolute;
            width:100%;
            background-color: $colorWhite;
            left:0;
            top:115px;
            padding:15px;
            border-radius: 6px;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
            @include scrollBar();
            transition: 1s;
            opacity: 0;
            visibility: hidden;
            &-list{
                flex-direction: column;
                gap:0;
                &__item{
                    padding:12px;
                    &.o-link{
                        display: block;
                    }
                    &.o-link-plus{
                        display: block;
                    }
                    & + li {
                        border-top: 1px solid #eee;
                    }
                }
            }
            &.active{
                top:76px;
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &__action{
        @include flex();
        @include flexWrap($wrap:nowrap);
        gap:20px;
        &__auth{
            display: inline-block;
            border-radius: 8px;
            border: 2px solid $colorGreen;
            white-space: nowrap;
            text-align: center;
            padding:0 10px;
            @include flex();
            @include flexCenterVertical($align:center);
            transition: .5s;
            &.login{
                @include flexCenterHorizontal();
                gap:5px;
                padding-right: 25px;
                //border-radius: 23px;
                img{
                    width: 35px;
                }
            }
            &.register{
                @include flexCenterHorizontal();
                padding:0 43px;
            }
            &:hover{
                background-color: $colorGreen;
                color: $colorWhite;
            }
            @media (max-width: 767px){//1382px
                display: none;
            }
        }
        &__rcplus{
            > a{
                display:inline-block;
                border: 1px solid #787878;
                border-radius:25px;
                padding: 7px 42px;
                font-size: 18px;
                font-weight: 500;
                color:$colorPrimaryBack;
                margin-top: 4px;
            }
            &.dropdown {

                .dropdown__list{
                    //display:none;
                    background-color: $colorWhite;
                    padding:10px 0;
                    border-radius:6px;
                    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12), 0 1px 5px 0 rgba(0, 0, 0, .20);
                    position: absolute;
                    transform: translateY(15px);
                    right: 0;
                    transition: .5s;
                    opacity: 0;
                    visibility: hidden;
                    &__item {
                        padding:0 15px;
                        a{
                            display:block;
                            padding:5px 0;
                            //background-color: #eee;
                            margin-bottom: 1px;
                            border-radius:5px;
                            color:$colorPrimaryBack;
                            white-space: nowrap;
                        }
                        &:hover{
                            background-color: $colorPrimaryBg4;
                            a {
                                color:$colorGreen;
                            }
                        }
                    }
                    &.active{
                        transform: translateY(0);
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
            @media (max-width: 1382px){
                display: none;
            }
        }
    }
}