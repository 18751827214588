.type {
  &New {
    display: inline-block;
    padding: 5px 4px;
    border-radius: 5px;
    background-color: #0077FF;
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: #fff;
    margin-left: 10px;
  }
}

.label {
  font-family: "OpenSans-Regular", sans-serif;
  display: inline-block;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #5476B8;
  text-transform: uppercase;
  margin-bottom: 12px;

  &Required {
    font-size: 0.75rem;
    line-height: 1rem;
    color: #E6402E;
    display: inline-block;
    margin-left: 3px;
  }
}

.h1 {
  font-size: 4.5rem;
  font-family: "Krona One", sans-serif;
  line-height: 1.375rem;
  line-height: 4.8rem;
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 400;
  background: linear-gradient(151deg, #04C8A5 16.53%, #009B7F 90.38%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width:1440px){
    font-size: 2.8rem;
    line-height: 3.4rem;
  }
}

.h2{
  font-size: 36px;
  font-style: normal;
  font-family: "Krona One", sans-serif;
  font-weight: 400;
  line-height: 45px;
  letter-spacing:  -0.72px;
  @media (max-width:1440px){
    font-size: 28px;
    line-height: 35px ;
  }
}

.h3{
  font-size: 28px;
  font-style: normal;
  font-family: "Krona One", sans-serif;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.56px;
  @media (max-width:1440px){
    font-size: 20px;
    line-height: 28px ;
  }
}

.h4{
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: -0.56px;
}