@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.banner__right-game{
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    gap:30px;
    background: linear-gradient(142deg, #FAFAFA 18.2%, #D9E9ED 78.19%);
    border:30px;
    @include flexCenterVertical($align:center);
    padding:25px 0 25px 25px;
    @media (max-width: 767px){
        grid-template-columns: repeat(1, 1fr);
        padding:15px;
    }
    &__left{
        h2{
            margin-bottom: 25px;
        }
        p{
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 35px;
        }
        @media (max-width: 767px){
            order:2;
        }
    }
    &__right{
        height:400px;
        overflow: hidden;
        border-radius: 20px 0 0 20px;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
        @media (max-width: 767px){
            order:1;
            height: auto;
            border-radius: 20px;
        }
    }
}
.games{
    &__content{
        padding-bottom: 50px;
        h2{
            max-width: 600px;
        }
    }
    &__list{
        padding: 35px 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap:50px;
        @media (max-width: 1200px){
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 767px){
            grid-template-columns: repeat(1, 1fr);
            gap:25px;
        }
    }
}