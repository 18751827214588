@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixins";
.banner{
    &__content{
        margin-top: 60px;
        margin-bottom: 120px;
        @include flex();
        @include flexWrap($wrap:nowrap);
        gap:80px;
        @media (max-width:1200px){
            display: block;
            margin-bottom: 50px;
        }
    }
    &__left{
        width: 720px;
        h1{

        }
        p{
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px; 
            letter-spacing: -0.48px;
            margin-bottom: 30px;
        }
        &__info{
            margin-top:80px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap:80px;
            background-color: $colorWhite;
            @media (max-width:675px){
                grid-template-columns: 100%;
                gap: 35px;
            }
            &__item{
                border: 2px solid #787878;
                padding:40px;
                border-radius:15px 0 15px 15px;
                padding-right: 80px;
                strong{
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: -0.32px;    
                }
                p{
                    margin-top: 15px;
                    margin-bottom:0;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                }
                &__subImg{
                    position: absolute;
                    top:0;
                    right: 0;
                    border: 2px solid #787878;
                    border-bottom-left-radius: 30px;
                    height: 80px;
                    width: 80px;
                    border-top:none;
                    border-right:none;
                    box-shadow: 4px -2px 0px 0px #fff;
                    span{
                        position:absolute;
                        top:-20px;
                        right:-20px;
                        height: 80px;
                        width: 80px;
                        border-radius: 15px;
                        background:linear-gradient(124deg, #5B5B5B 20.22%, #050505 89%);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    &:before{
                        content: "";
                        position: absolute;
                        height: 35px;
                        width: 60px;
                        box-shadow: 22px -16px 0 0 #fff;
                        border-top-right-radius: 15px;
                        left: -60px;
                        border: 2px solid #787878;
                        top: -2px;
                        border-left: none;
                        border-bottom: none;
                    }
                    &:after{
                        content: "";
                        position: absolute;
                        height: 34px;
                        width: 30px;
                        box-shadow: 6px -16px 0 0 #fff;
                        border-top-right-radius: 15px;
                        right: -2px;
                        top: 78px;
                        border-top: 2px solid #787878;
                        border-right: 2px solid #787878;
                    }
                }
            }
        }

        @media (max-width:1200px){
            width: 100%;
            &__info{
                padding-right: 30px;
            }
        }
    }
    &__right{
        width: calc(100% - 720px);
        &__card{
            background: linear-gradient(143deg, #FAFAFA 17.07%, #D9E9ED 77.89%);
            height: 100%;
            border-radius: 30px;
            .banner-slide{
                &__item{
                    & > img{
                        //max-width: 1160px;
                        //position: absolute;
                        //right:-125px;
                        top:-25px;
                        @media (max-width:2000px){
                            top:40px;
                        }
                        @media (max-width:1712px){
                            top:140px;
                        }
                        @media (max-width:1415px){
                            top:250px;
                        }
                    }
                    &__info{
                        position:absolute;
                        top:25px;
                        left:35px;
                        background-color: $colorWhite;
                        filter: drop-shadow(5px 7px 17px rgba(0, 0, 0, 0.17));
                        padding:35px;
                        border-radius: 25px;
                        max-width: 400px;
                        p{
                            letter-spacing: -0.28px;
                            line-height: 20px;
                            margin-top:25px;
                        }
                        .sub-img{
                            content:"";
                            position:absolute;
                            right:0px;
                            top:-50px;
                            width:170px;
                            height: 133px;
                            img{
                                position:absolute;
                                &:first-child{
                                    width:170px;
                                    right:3px;
                                }
                                &:last-child{
                                    width:110px;
                                    bottom:-8px;
                                    left:-22px;
                                }
                            }
                        }
                    }
                }
            }
        }
        @media (max-width:1200px){
            width: 100%;
            margin-top:35px;
            &__card{
                .banner-slide{
                    &__item{
                        padding:15px;
                        & > img{
                            display:none;
                            /* position:absolute;
                            top:50%;
                            transform: translateY(-50%); */
                        }
                        &__info{
                            position: inherit;
                            max-width: 100%;
                            top:inherit;
                            left:inherit;
                        }
                    }
                }
            }
        }
    }
}