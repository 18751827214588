@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixins";

.bestPartner__item{
    background:linear-gradient(136deg, #FAFAFA 18.12%, #D9E9ED 85.2%);
    border-radius: 15px;
    overflow: hidden;
    padding:25px 20px;
    &__img{
        height: 120px;
        width: 120px;
        border-radius: 50%;
        background-color: $colorPrimaryBg4;
        margin-bottom: 18px;
    }
    h3{
        margin-bottom:16px;
        font-size: 18px;
    }
    p{
        line-height: 20px;
        font-size: 14px;
        margin-bottom: 30px;
    }
    a{
        white-space: nowrap;
    }
}