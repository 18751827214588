@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixins";

.plaque{
    &__content{
        background: linear-gradient(143deg, #525252 5.23%, #1B1B1B 64.67%);
        border-radius: 25px;
        padding: 30px;
        @include flex();
        @include flexWrap($wrap:nowrap);
        @include flexCenterVertical($align:center);
        @include flexCenterHorizontal($align:space-around);
        gap:30px;
        margin:60px 0;
        *{
            color: $colorWhite;
        }
        @media (max-width: 1440px){
            padding: 20px 15px;
            margin: 30px 0;
        }
        @media (max-width: 1044px){
            flex-direction: column;
            gap: 50px;
        }
    }
    &__left{
        max-width:630px;
    }
    &__center{
        @include flex();
        @include flexWrap($wrap:nowrap);
        @include flexCenterVertical($align:center);
        gap:30px;
        max-width:550px;
        p{
            margin-top:25px;
        }
    }
    &__img {

        &:before{
            content:"";
            position: absolute;
            height: 80px;
            width: 80px;
            border-radius: 10px;
            left:50%;
            transform: translateX(-50%);
            background: linear-gradient(151deg, #04C8A5 16.25%, #009B7F 88.84%);
        }
    }
    &__right{
        width: max-content;
    }
}