@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixins";

.plaque-small{

    &__content{
        max-width: 1200px;
        background: linear-gradient(143deg, #525252 5.23%, #1B1B1B 64.67%);
        border-radius: 25px;
        padding: 30px;
        @include flex();
        @include flexWrap($wrap:nowrap);
        @include flexCenterVertical($align:center);
        @include flexCenterHorizontal($align:space-around);
        gap:35px;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 50px;
        @media (max-width: 991px){
            @include flexWrap($wrap:wrap);
            h2{
                text-align: center;
            }
        }
    }
    &__left{
        width:220px;
        &:before{
            content: "";
            position: absolute;
            height: 150px;
            width: 150px;
            border-radius: 50%;
            left:50%;
            top:50%;
            transform: translate(-50%, -50%);
            background: linear-gradient(151deg, #04C8A5 16.25%, #009B7F 88.84%);
        }
        img.double-hand{
            width: 220px;
            top:20px;
        }
        img.alert-ok{
            position:absolute;
            top: -45px;
            left:50%;
            transform: translateX(-50%);
            width: 50px;
        }
    }
    &__center{
        h2{
            color:$colorWhite;
        }
    }
}