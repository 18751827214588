@import './assets/styles/variables';
.app {
  min-height: 100vh;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  //background-color: white;

  .appTitle {
    font-size: 4rem;
    line-height: 3.5rem;
    text-align: center;
  }

  .appSubtitle {
    font-size: 1rem;
  }

  .appIcon {
    background-color: $colorSuccess;
  }
}