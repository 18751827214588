@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

//instruction
.instruction{
    &:before{
        content:"";
        position: absolute;
        width: 90px;
        height: 90px;
        border-radius:50%;
        background: linear-gradient(154deg, #C1FFF4 15.15%, #006452 82.82%);
        filter: blur(18.5px);
        top:0;
        transform: translateY(-50%);
        left: -12px;
    }
    &__content{
        padding-bottom: 35px;
        &:before{
            content:"";
            position: absolute;
            width: 40px;
            height: 40px;
            border-radius:50%;
            background: linear-gradient(154deg, #C1FFF4 15.15%, #006452 82.82%);
            filter: blur(1.5px);
            bottom: 35%;
            left: -121px;
        }
        &:after{
            content:"";
            position: absolute;
            width: 40px;
            height: 40px;
            border-radius:50%;
            background: linear-gradient(154deg, #C1FFF4 15.15%, #006452 82.82%);
            filter: blur(3.5px);
            top: 35%;
            right: -120px;
            @media (max-width: 2320px){
                right: 0;
            }
        }
    }
    &__top{
        @include flex();
        @include flexWrap($wrap:nowrap);
        &__left{
            width:calc(100% - 400px);
            display:grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 170px;
            border: 2px solid #787878;
            border-radius: 20px;
            padding: 40px;
            &__do{
                @include flex();
                @include flexWrap($wrap:nowrap);
                &:before{
                    content: "";
                    position: absolute;
                    height: 100%;
                    width: 2px;
                    background-color: #787878;
                    top:0;
                    right: -85px;
                }
            }
            &__img{
                @include flex();
                height: 406px;
                width:142px;
                img{
                    &:first-child{
                        width:170px;
                        height: 352px;
                        top:48px;
                    }
                    &:last-child{
                        width: 160px;
                        height: 406px;
                        left: -88px;
                        top: 10px;
                    }
                }
            }
            &__text{
                width: calc(100% - 142px);
                padding-left: 115px;
                p{
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 28px;
                    letter-spacing: -0.36px;
                    margin-top:32px;
                }
            }
        }
        &__right{
            width: 400px;
            background: linear-gradient(143deg, #525252 5.23%, #1B1B1B 64.67%);
            border-radius:20px;
            margin-left: 80px;
            @include flex();
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px 50px;
            &__card{

                .other-img{
                    position: absolute;
                    &.pin1{
                        top: -58px;
                        left: -35px;
                        transform: rotate(-37deg);
                    }
                    &.pin2{
                        width: 110px;
                        left: -51px;
                        top: -56px;
                        transform: rotate(14deg);
                    }
                }
            }
            &__top{
                padding-left:150px;
                & > img{
                    position: absolute;
                    width: 100px;
                    top:50%;
                    transform: translateY(-50%);
                    left:15px;
                }
                p{
                    line-height: 20px;
                    letter-spacing: -0.28px;
                    max-width: 359px;
                }
            }
            &__bottom{
                margin-top:50px;
                ul{
                    margin-top:12px;
                    max-width: 400px;
                    li{
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 35px;
                        letter-spacing: -0.36px;
                        padding-left: 20px;
                        &:before{
                            content: "";
                            position:absolute;
                            width: 9px;
                            height: 9px;
                            border-radius: 50%;
                            background-color: #00A385;
                            left: 0;
                            top: 12px;
                        }
                    }
                }
            }
            &__img{
                top:-88px;
            }
            &__text{
                text-align: center;
                margin-top: -27px;
                h3{
                    color:$colorWhite;
                }
                p{
                    color:$colorWhite;
                    font-weight: 400;
                    line-height: 18px; 
                    letter-spacing: -0.28px;
                    margin-top:20px;
                    margin-bottom:20px;
                }
            }
        }
        @media (max-width:1700px){
            display: block;
            &__left{
                width: 100%;
                @media (max-width:1200px){
                    grid-template-columns: 100%;
                    padding:15px;
                    gap: 70px;
                    &__do{
                        //border-bottom: 2px solid #787878;
                        align-items: center;
                        &:before{
                            height: 2px;
                            width: 100%;
                            top:inherit;
                            left:0;
                            right:inherit;
                            bottom: 0;
                        }
                        @media (max-width:767px){
                            @include flexWrap($wrap:wrap);
                            @include flexCenterHorizontal($align:center);
                        }

                    }
                    &__img{
                        width:auto;
                    }
                    &__text{
                        padding-left: 50px;
                        width:auto;
                        @media (max-width:767px){
                            padding-left: 0;
                            margin-top:25px;
                            margin-bottom: 25px;
                        }
                    }
                }
            }
            &__right{
                margin-left:0;
                width: 100%;
                margin-top:20px;
                padding-top: 30px;
                padding-bottom: 30px;
                &__img{
                    top:inherit;
                }
                &__text{
                    margin-top:35px;
                }
                &__bottom ul{
                    max-width: 100%;
                }
            }
        }
    }
    &__bottom{
        background: linear-gradient(180deg, #DAEAEE 0%, rgba(217, 217, 217, 0.00) 100%);
        padding:30px;
        margin-top: 35px;
        border-radius: 30px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap:90px;
        @media (max-width:1199px){
            grid-template-columns: repeat(2, 1fr);
            gap:50px;
        }
        @media (max-width:991px){
            grid-template-columns: 100%;
            padding: 20px 15px;
        }
        &__item{
            strong{
                font-family: "Krona One", sans-serif;
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
                letter-spacing: -0.48px;
            }
            p{
                margin-top:20px;
                font-size: 24px;
                line-height: 32px;
                letter-spacing: -0.48px;
            }
        }
    }
}

//partners
.partners{
    background: linear-gradient(165deg, #1EDEB0 9.99%, #0E806B 48.6%, #005C4C 88.85%);
    &:before{
        content:"";
        position: absolute;
        width: 170px;
        height: 170px;
        border-radius:50%;
        background: linear-gradient(154deg, #C1FFF4 15.15%, #006452 82.82%);
        filter: blur(18.5px);
        top:50%;
        transform: translateY(-50%);
        left: -56px;
    }
    &__content{
        padding-top: 30px;
        padding-bottom: 30px;
        &:before{
            content:"";
            position: absolute;
            width: 50px;
            height: 50px;
            border-radius:50%;
            background: linear-gradient(154deg, #C1FFF4 15.15%, #006452 82.82%);
            filter: blur(8.5px);
            bottom:18%;
            left: -98px;
        }
        &:after{
            content:"";
            position: absolute;
            width: 50px;
            height: 50px;
            border-radius:50%;
            background: linear-gradient(154deg, #C1FFF4 15.15%, #006452 82.82%);
            filter: blur(6px);
            top:18%;
            right: -98px;
            @media (max-width: 2300px){
                right: 0;
            }
        }
        img[alt="cat"]{
            position: absolute;
            width: 280px;
            right: 0;
            top: -22%;
            @media (max-width: 991px){
                display: none;
            }
        }
        img[alt="flower"]{
            position: absolute;
            width: 120px;
            right: 33%;
            top: -17px;
        }
        img[alt="woman"]{
            position: absolute;
            top: -48%;
            right: 14%;
            width: 393px;
            @media (max-width: 1616px){
                display: none;
            }
        }
        img[alt="radio"]{
            position: absolute;
            top: 9%;
            right: 14%;
            width: 150px;
        }
        h2{
            color: $colorWhite;
            margin-bottom: 30px;
        }
        p{
            color: $colorWhite;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: -0.48px;
            max-width: 933px;
        }
    }
    &__card{
        img{
            &[alt="orange"]{
                position: absolute;
                bottom: 0;
                right: calc(10% );
                width: 295px;
            }
        }
    }
    &__list{
        @include flex();
        @include flexWrap($wrap:wrap);
        gap:30px;
        padding: 40px 0px;
        &:before{
            content: '';
            position: absolute;
            width: 295px;
            height: 243px;
            bottom:0;
            right:0;
            background-image: url('../../../assets/images/defaults/partner-orange.svg');
            //background-color: red;
        }
    }
    &__item{
        height: 200px;
        width: 370px;
        background-color: $colorWhite;
        border-radius: 25px;
        overflow: hidden;
        padding:15px;
        @include flex();
        @include flexCenterVertical($align:center);
        @include flexCenterHorizontal($align:center);
    }
}

//infos
.infos{
    &__content{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap:60px;
        padding-top: 70px;
        padding-bottom: 70px;
        @media (max-width: 1200px){
            grid-template-columns: repeat(1, 1fr);
            padding-bottom: 0;
            gap:0;
        }
    }
    &__left{
        h2{

        }
    }
    &__list{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap:20px;
        margin-top: 35px;
        padding-left: 20px;
        @media (max-width: 1600px){
            grid-template-columns: repeat(1, 1fr);
            padding-left: 0;
        }
    }
    &__item{
        @include flex();
        @include flexCenterVertical($align:center);
        //gap: 20px;
        & + &{
            margin-top: 25px;
        }
        &__left{
            width: 160px;
            height: 160px;
            border-radius:50%;
            border:2px solid #787878;
            @include flex();
            @include flexCenterVertical($align:center);
            @include flexCenterHorizontal($align:center);
            span{
                background: linear-gradient(129deg, #717171 18.75%, #000 81.48%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-family: "Krona One", sans-serif;
                font-size: 120px;
                line-height: 103px;
            }
            & > img{
                position: absolute;
                width: 120px;
                top: -15px;
                left: -28px;
            }
            @media (max-width: 767px){
                height: 75px;
                width: 75px;
                span{
                    font-size: 60px;
                }
                & > img{
                    left: 0;
                }
            }
        }
        &__right{
            width: calc(100% - 160px);
            padding-left: 20px;
            padding-right: 20px;
            p{
                font-weight: 500;
                line-height: 20px;
                letter-spacing: -0.28px;
            }
            @media (max-width: 767px){
                width: calc(100% - 75px);
            }
        }
    }
    &__appLInk{
        width: max-content;
        @include flex();
        @include flexCenterVertical($align:center);
        @include flexCenterHorizontal($align:center);
        gap:25px;
        margin:60px auto;
        @media (max-width: 767px){
            flex-direction: column;
        }
        &__left{
            background: linear-gradient(145deg, #525252 2.38%, #1B1B1B 56.89%);
            padding:30px;
            border-radius: 20px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap:20px;
            a{
                background-color: $colorWhite;
                display: block;
                text-align: center;
                border-radius: 15px;
                padding: 15px 38px;
                span{
                    display: block;
                    margin-top:15px;
                    white-space: nowrap;
                }
            }
            @media (max-width:767px){
                padding:15px;
                a{
                    padding: 15px;
                    span{
                        margin-top:0;
                    }
                }
            }
        }
        &__right{
            p{
                margin-top:20px;
            }
        }
    }
    &__right{
        &__top{
            background: linear-gradient(147deg, #525252 2.52%, #1B1B1B 60.16%);
            border-radius: 20px;
            padding:30px;
            @media (max-width: 767px){
                padding: 20px 15px;
            }
            h2{
                color:$colorWhite
            }
            &__list{
                @include flex();
                @include flexCenterHorizontal();
                @include flexWrap($wrap:wrap);
                margin-top:25px;
                gap:25px;
                @media (max-width:675px){
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap:15px;
                }
            }
            &__item{
                background-color: $colorWhite;
                border-radius:10px;
                width:160px;
                height: 120px;
                padding:10px;
                @include flex();
                @include flexCenterVertical($align:center);
                @include flexCenterHorizontal($align:center);
                @media (max-width:675px){
                    width: auto;
                }
            }
        }
        &__bottom{
            margin-top: 40px;
            &__desc{
                @include flex();
                @include flexWrap($wrap:nowrap);
                margin-top: 50PX;
                gap:25px;
                &__img{
                    width: 80px;
                    height: 80px;
                    border-radius: 10px;
                    background:linear-gradient(145deg, #525252 2.38%, #1B1B1B 56.89%);
                    img{
                        position: absolute;
                        top: -19px;
                        left: -23px;
                    }
                }
                &__text{
                    width: calc(100% - 80px);
                    strong{
                        font-family: "Krona One", sans-serif;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: -0.32px;
                        font-weight: 600;
                    }
                    p{
                        line-height: 20px;
                        letter-spacing: -0.28px;
                        margin-top:10px;
                    }
                }
                @media (max-width: 575px){
                    flex-direction: column;
                    &__text{
                        width:100%;
                        border-top: 1px solid silver;
                        border-bottom: 1px solid silver;
                        padding-top:15px;
                        padding-bottom:15px;
                        margin-bottom: 20px;
                    }
                }
            }
        }
        &__countries{
            h2{
                margin-bottom: 35px;
            }
            &__list{
                @include flex();
                @include flexWrap($wrap:wrap);
                gap:50px;
            }
            &__item{
                img{
                    width: 93px;
                }
                span{
                    display: block;
                    margin-top:5px;
                }
            }
        }
    }
}

//contacts
.contact{
    &__content{
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        padding-bottom:35px;
        gap: 50px;
        align-items: center;
        @media (max-width: 1200px){
            grid-template-columns: repeat(1, 1fr);
        }
    }
    &__left{
        padding:80px;
        border-radius: 25px;
        background: linear-gradient(150deg, #525252 3.86%, #1B1B1B 54.94%);;
        @media (max-width: 991px){
            padding:20px 15px;
        }
        &__top{
            margin-bottom: 25px;
            @include flex();
            @include flexWrap($wrap:nowrap);
            @include flexCenterVertical($align:center);
            h2, p{
                color: $colorWhite;
            }
            h2{
                padding-right: 25px;
            }
            p{
                font-size: 18px;
                line-height: 28px;
                letter-spacing: -0.36px;
            }
            @media (max-width: 1440px){
                @include flexWrap($wrap:wrap);
                gap:15px;
            }
        }
    }
    &__right{
        img{
            width:190px;
        }
        h3{
            margin-top:35px;
            margin-bottom: 20px;
        }
        p{
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 36px;
            margin-bottom: 35px;
        }
    }
}

//trust us
.trust-us{
    &__content{
        background: linear-gradient(152deg, #FAFAFA 22.73%, #D9E9ED 78.59%);
        padding:35px 0;
        border-radius: 25px;
        padding:20px 40px;
        @include flex();
        @include flexWrap($wrap:nowrap);
        @include flexCenterVertical($align:center);
        gap:25px;
        @media (max-width: 840px){
            display: block;
            padding: 20px 15px 70px 15px;
            .slick-slide{
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    &__left{
        width: 450px;
        @media (max-width: 840px){
            width: 100%;
        }
        h2{
            margin-bottom: 25px;
        }
        p, a{
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 36px;
            margin-bottom: 35px;
        }
        a{
            display: block;
            text-decoration: underline;
            color:$colorGreen
        }
    }
    &__right{
        width: calc(100% - 450px);
        @media (max-width: 840px){
            width: 100%;
        }
        &__slide{

        }
        &__item{
            border:2px solid #787878;
            background-color: $colorWhite;
            padding:25px;
            border-radius: 25px;
            min-height: 300px;
            p{
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }
            &__profil{
                margin-top: 25px;
                @include flex();
                @include flexWrap($wrap:wrap);
                @include flexCenterVertical($align:center);
            }
            &__img{
                width:70px;
                height: 70px;
                border-radius: 50%;
                margin-right: 20px;
            }
            &__text{
                strong{
                    font-family: 'Krona One', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    display: block;
                }
                span{
                    display: block;
                }
            }
        }
    }
}

//actualities
.actualities{
    &__content{
        padding:100px 0 120px 0;
        h2{
            text-align: center;
        }
    }
    &__card{
        padding-top: 45px;
        .slick-dots li{
            background-color: $colorPrimaryBg4;
        }
    }
}