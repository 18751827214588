/*
* COLOR 
*/

//==>Default
$colorWhite:  #fff;
$colorBlack:  #000;
$colorRed:    #ff0000;
$colorYellow: #ffd000;
$colorViolet: #8913C1;
$colorGreen: #008981;

//===>System color
$colorSuccess:          #14C773;
$colorDanger:           #E6402E;
$colorDangerSecondary:  #ea4845;
$colorWarning:          #FAB650;
$colorWarningSecondary: #FEF9EC;
$colorHighlight:        rgba(249, 223, 144, 0.5);


$colorPrimaryBack:     #272727;
$colorPrimaryClear:    #F3F4FA;
$colorPrimaryClear2:   #f3f4fa;
$colorPrimaryBlue:     #0077FF;
$colorPrimaryBlue1:    #e5f1fe;
$colorPrimaryBlue2:    #D5E4E9;
$colorPrimaryBlue3:    #8097C3;
$colorPrimaryGrey:     #929A9D;
$colorPrimaryOrange:   #FA8950;

$colorX1: #dde9ff;

$colorBgDefault:  #000810;
$colorBgDefault2:  #525252;
$colorBgDefault3:  #1B1B1B;

$colorPrimaryBg: #DAEAEE;
$colorPrimaryBg2: #D9D9D9;
$colorPrimaryBg3: #FAFAFA;
$colorPrimaryBg4: #D9E9ED;
$colorSecondaryBg: #1EDEB0;
$colorSecondaryBg1: #0E806B;
$colorSecondaryBg3: #005C4C;
$colorSecondaryBg4: #B0EADF;
$colorSecondaryBg5: #1FB69B;
$colorSecondaryBg6: #004337;
$colorSecondaryBg7: #BEF3C3;
