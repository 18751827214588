@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.banner{
    &__content{
        &-partnership{
            @media (max-width: 1500px){
                flex-wrap: wrap;
            }
        }
    }
    &__left{
        &-partnership{
            h3{
                margin-bottom: 20px;
            }
            @media (max-width: 1500px){
                width: 100%;
            }
        }
    }
    &__right{

        &-partnership{
            background: linear-gradient(139deg, #FAFAFA 19.48%, #D9E9ED 83.69%);
            padding:25px;
            border-radius: 25px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 70px;
            align-items: flex-end;
            @media (max-width: 1500px){
                width: 100%;
                align-items: center;
            }
            @media (max-width: 991px){
                grid-template-columns: repeat(1, 1fr);
                grid-gap: 30px;
            }
            &__left h3{
                margin-bottom: 20px;
            }
            &__left p{
                font-size: 14px;
                line-height: 24px;
            }
            &__right{
                background: linear-gradient(180deg, #666 -3.32%, #000 114.29%);
                filter: drop-shadow(5px 7px 17px rgba(0, 0, 0, 0.17));
                border-radius: 20px;
                //overflow: hidden;
                padding:25px;
                @include flex();
                flex-direction: column;
                @include flexCenterVertical($align:center);
                @include flexCenterHorizontal($align:center);
                img{
                    position: absolute;
                    &.moneypaper1{
                        width: 80px;
                        left: calc(50% - 139px);
                        bottom: 56px;
                    }
                    &.moneypaper2{
                        width: 90px;
                        top: -27px ;
                        right: 145px;
                    }
                    &.moneypaper3{
                        width: 45px;
                        top: calc(50% - 77px);
                        left: calc(50% - 68px);
                    }
                    &.moneypaper4{
                        width: 55px;
                        bottom: 120px;
                        left: calc(50% - 8px);
                        transform: rotate(-37deg);
                    }
                    &.moneypaper5{
                        width: 100px;
                        bottom: 74px;
                        right: -33px;   
                        transform: rotate(56deg);
                        @media (max-width: 991px){
                            display: none;
                        }
                    }
                    &.moneypaper6{
                        width: 40px;
                        top: calc(50% - 124px);
                        right: calc(50% - 131px);
                        transform: rotate(111deg);
                    }
                }
                span{
                    font-family: "Retro Boldy", sans-serif;
                    font-size: 60px;
                    line-height: 54px;
                    text-shadow: 5px 7px 17px rgba(0, 0, 0, 0.17);
                    @media (max-width: 767px){
                        font-size: 30px;
                    }
                    &.quote{
                        display: block;
                        color:#36FF9F;
                        height: max-content;
                        padding-top:30px;
                    }
                    &.t1{
                        background: linear-gradient(180deg, #FFF -10.44%, #AAF0FF 103.63%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                    &.t2{
                        background: linear-gradient(180deg, #BBFFDE -16.4%, #00C88C 125.42%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                    &.t3{
                        color:#36FF9F;
                    }
                }
                .text-inline{
                    @include flex();
                    @include flexCenterHorizontal($align:center);
                    @include flexCenterVertical($align:center);
                    gap:18px;
                    margin-bottom: 10px;
                    p{
                        text-align: right;
                        color:$colorWhite;
                        font-family: "Retro Boldy", sans-serif;
                        font-size: 22px;
                        line-height: 20px;
                    }
                }
                p.signature{
                    color:$colorWhite;
                    margin-top:35px;
                    font-weight: 500;
                }
            }
        }
    }
}

.partnershipType{
    &__content{
        padding-bottom: 70px;
        max-width: 1760px;
    }
    &__top{
        @include flex();
        @include flexCenterHorizontal($align:space-between);
        @include flexCenterVertical($align:center);
        gap:25px;
        p{
            font-size: 18px;
            line-height: 28px;
            max-width: 720px;
            padding:20px;
            border-top-right-radius: 20px;
            background: linear-gradient(187deg, #FBFBFB -8.5%, #DCF2F8 86.48%);
        }
        @media (max-width: 991px){
            flex-direction: column;
            @include flexCenterVertical($align:start);            
            p{
                max-width: 100%;
            }
        }
    }
    &__list{
        border:4px solid #C1F4EB;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap:35px;
        padding:35px;
        @media (max-width: 1199px){
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 991px){
            padding-right: 15px;
            padding-left: 15px;
        }
        @media (max-width: 767px){
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

.bestPartner{
    &__content{

    }
    &__slide{
        padding-top: 30px;
        padding-bottom: 100px;
        .slick-dots {
            text-align: left;
            padding-left: 20px;
            li{
                background-color: $colorPrimaryBg4;
            }
        }
    }
}