@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixins";

.plaque-phone{
    &__content{
        padding: 160px 0 120px 0;
        @media (max-width: 1440px){
            padding: 60px 0 90px 0;
        }
        @media (max-width: 991px){
            padding: 0 0 25px 0;
        }
        &:before{
            content:"";
            position: absolute;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: linear-gradient(154deg, #C1FFF4 15.15%, #006452 82.82%);
            filter: blur(8.5px);
            left: 80px;
            top: 80px;
        }
    }
    &__card{
        max-width: 528px;
        background: linear-gradient(143deg, #525252 5.23%, #1B1B1B 64.67%);
        margin: 0 auto;
        border-radius: 25px;
        padding:80px;
        @media (max-width: 675px){
            padding:30px;
        }
        *{
            color:$colorWhite;
        }
        p{
            margin-top: 20px;
            margin-bottom: 25px;
        }
        img.phone{
            position: absolute;
            top:50%;
            left:-230px;
            transform: translateY(-50%);
            @media (max-width: 991px){
                display: none;
            }
        }
        img.boy{
            position: absolute;
            right: calc(-65%);
            bottom: -73%;
            @media (max-width: 1440px){
                right: calc(-30%);
            }
            @media (max-width: 991px){
                display: none;
            }
        }
        &:before{
            content:"";
            position: absolute;
            width: 150px;
            height: 150px;
            border-radius: 50%;
            background: linear-gradient(154deg, #C1FFF4 15.15%, #006452 82.82%);
            filter: blur(8.5px);
            left: -57%;
            bottom: 20%;
        }
        &:after{
            content:"";
            position: absolute;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: linear-gradient(154deg, #C1FFF4 15.15%, #006452 82.82%);
            filter: blur(3.5px);
            left: 18px;
            top: 10px;
        }
    }
    &__list{
        @include flex();
        @include flexWrap($wrap:nowrap);
        gap: 30px;
        a{
            background-color: $colorWhite;
            display: block;
            width:120px;
            height: 120px;
            border-radius: 10px;
            @include flex();
            @include flexCenterVertical($align:center);
            @include flexCenterHorizontal($align:center);
        }

    }
    &__item span{
        display: block;
        text-align: center;
        margin-top: 10px;
        
    }
}