@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.banner{
    &__content-actu{
        margin-bottom: 50px;
    }
    &__left{
        select{
            border:2px solid #787878;
            height: 42px;
            border-radius: 10px;
            margin-top: 25px;
            width: 100%;
            background-color: $colorWhite;
            padding-left: 15px;
        }
    }
    &__right{
        &.banner-right{
            background: linear-gradient(131deg, #FAFAFA 32.96%, #D9E9ED 80.91%);
            padding:25px;
            border-radius: 25px;
            @include flex();
            @include flexWrap($wrap:nowrap);
            @include flexCenterVertical($align:center);
            @include flexCenterHorizontal($align:space-around);
            gap:25px;
            h3{
                max-width: 199px;
            }
            @media (max-width: 1440px){
                @include flexWrap($wrap:wrap);
                @include flexCenterHorizontal($align:flex-start);
                h3{
                    max-width: initial
                }
            }
        }
        .banner-right__form{
            @include flex();
            @include flexWrap($wrap:wrap);
            @include flexCenterVertical($align:flex-start);
            gap:25px;
            input[type="text"]{
                border:2px solid #787878;
                @media (max-width: 575px){
                    width: 250px;
                }
            }
            input[type="radio"]{
                //width: 42px;
                @media (max-width: 767px){
                    //width: 20px;
                }
            }
            &__left{
                @include flex();
                @include flexWrap($wrap:nowrap);
                gap:30px;
                flex-direction: column
            }
            &__check{
                @include flex();
                @include flexCenterVertical($align:center);
                gap:15px;
                p{
                    width:131px;
                    @media (max-width: 767px){
                        width: auto;
                    }
                }
                & div{
                    @include flex();
                    @include flexWrap($wrap:nowrap);
                    @include flexCenterVertical($align:center);
                    gap:15px;
                    @media (max-width: 767px){
                        gap:10px;
                    }
                }
            }
            & div{
                /*@include flex();
                @include flexWrap($wrap:nowrap);*/
                //@include flexCenterVertical($align:center);
                /*gap:15px;
                @media (max-width: 767px){
                    gap:10px;
                }*/
            }
        }
    }
}

.actualities{
    &__content{
        @media (max-width: 1440px){
            padding: 30px 0 70px 0;
        }
    }
    &__list{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap:35px;
        @media (max-width: 1200px){
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 767px){
            grid-template-columns: repeat(1, 1fr);
        }
    }
}