@import './../variables';

*{
  z-index: 5;
  font-size: 0.875rem;
  line-height: 1.2rem;
  font-family: "Azeret Mono", sans-serif;
  font-weight: 400;
  color: $colorPrimaryBack;
}

body {
  background-color: $colorWhite;
  overflow-x: hidden;
}

main.main{
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - (75.8px + 410.39px));
}

//form
.form-x2{
  display:grid;
  grid-template-columns: repeat(2, 1fr);
  gap:35px;
  @media (max-width: 767px) {
    grid-template-columns: 100%;
    gap: 0;
  }
}

//slick
.slick-slider .slick-arrow{
  display: none !important;
}
.slick-slide{
  padding: 0 15px;
}
.slick-dots{
  bottom: -45px;
  li {
    //button{
      height: 23px;
      width: 23px;
      border-radius: 50%;
      background-color: $colorWhite;
    //}
    button:before{
      opacity: 0  !important;;
    }
    &.slick-active {
      background-color: $colorGreen;
      button:before{
        opacity: 0 !important;
      }
    }
  }
}

//auth
.auth{
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before{
    content:"";
    position:absolute;
    height: 50%;
    width: 100%;
    left:0;
    bottom: 0;
    background: linear-gradient(139deg, #FAFAFA 19.48%, #D9E9ED 83.69%);
  }
  &__content{
    margin: 25px 15px;
    padding: 25px;
    border-radius: 20px;
    background: linear-gradient(125deg, #525252 5.61%, #1B1B1B 55.36%)
  }
  h2{
    background: linear-gradient(151deg, #04C8A5 16.53%, #009B7F 90.38%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  &__logo{
    display: inline-block;
    &__text{
      margin-top: 5px;
      span{
        display: block;
        font-family: "Krona One", sans-serif;
        font-size: 18px;
        &:nth-child(1){
          color: $colorGreen;
        }
        &:nth-child(2){
          color: $colorWhite;
          margin-top: 2px;
        }
      }
    }
  }
  &__card p{
    color:$colorWhite;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.36px;
    margin-top: 40px;
    a{
      color:$colorWhite;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.36px;
      text-decoration: underline !important;
      transition: .5s;
      &:hover{
        color:$colorGreen;
      }
    }
  }
}