@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixins";
.games{
    &__item{
        @include flex();
        @include flexWrap($wrap:nowrap);
        @include flexCenterVertical($align:center);
        gap:20px;
        border:2px solid #787878;
        overflow: hidden;
        border-radius: 10px;
        background-color: $colorWhite;
        filter: drop-shadow(5px 7px 17px rgba(0, 0, 0, 0.17));
        height: 320px;
    }
    &__left{
        height: 145px;
        width: 120px;
        overflow: hidden;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    &__right{
        width: calc(100% - 120px);
        padding-right: 10px;
        h3{
            font-size: 24px;
            margin-bottom: 25px;
        }
        p{
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            margin-bottom:35px;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}