@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixins";

.partnershipType{

    &__box{
        background: linear-gradient(139deg, #FAFAFA 19.48%, #D9E9ED 83.69%);
        border-radius: 20px;
        padding:25px;
        @media (max-width: 450px){
            padding: 15px 10px;
        }
        &__price{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap:25px;
            margin-top:25px;
            margin-bottom: 20px;
            @media (max-width: 450px){
                grid-template-columns: repeat(1, 1fr);
                gap: 5px;
            }
            &__left{
                background: linear-gradient(156deg, #5E5E5E -14.52%, #000 72.24%);
                border: 4px solid #C1F4EB;
                filter: drop-shadow(5px 7px 17px rgba(0, 0, 0, 0.17));
                border-radius: 20px;
                padding:10px;
                div{
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap:15px;
                    align-items: center;
                    &:first-child h3{
                        font-size: 18px;
                    }
                    &:last-child h3{
                        font-size: 14px;
                    }
                }
                h3{
                    color:$colorWhite;
                    text-align: right;
                }
                span{
                    color:#04C8A5;
                }
            }
            &__right{
                background: linear-gradient(158deg, #E8FAFF 7.8%, #99B3BA 83.16%);
                border: 4px solid #B0D7E2;
                filter: drop-shadow(5px 7px 17px rgba(0, 0, 0, 0.17));
                border-radius: 20px;
                padding:10px;
                h3{
                    font-size: 18px;
                }
            }
        }
        &__card{
            background-color: $colorWhite;
            border-radius: 20px;
            padding:25px;
            filter: drop-shadow(5px 7px 17px rgba(0, 0, 0, 0.17));
            ul{
                li{
                    padding-left:30px;
                    span{
                        line-height: 20px;
                    }
                    img{
                        position: absolute;
                        top:0;
                        left:0;
                        width:24px;
                    }
                    & + li{
                        margin-top:15px;
                    }
                }
            }
        }
        &__action{
            margin-top:30px;
            @include flex();
            @include flexCenterVertical($align:center);
            flex-wrap: wrap;
            gap:20px;
            a{
                padding-left:25px;
                padding-right: 25px;
                white-space: nowrap;
            }
        }
    }
}