@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.services{

    &__content{
        padding-right: 20px;
    }
    &__card{
        padding:60px 0;
    }
    &__left{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap:35px;
        @media (max-width: 1440px){
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 767px){
            grid-template-columns: repeat(1, 1fr);
        }
        .infos__item{
            background: linear-gradient(137deg, #FAFAFA 25.32%, #D9E9ED 80.09%);
            border-radius:25px;
            padding:25px 25px 25px 35px;
            margin-top: 0;
            @media (max-width: 991px){
                flex-direction: column;
                align-items: flex-start;
                gap:20px;
                padding: 25px 25px 10px 15px;
            }
            img{
                //position: absolute;
            }
            &__left{
                height: 140px;
                width: 140px;
                span{
                    -webkit-text-stroke-color: $colorWhite;
                    -webkit-text-stroke-width: 2px;
                }
            }
            &__right{
                width: calc(100% - 140px);
                @media (max-width: 991px){
                    width: 100%;
                    padding-left: 0;
                    padding-right: 0;
                }
                h3{
                    font-size: 24px;
                    margin-bottom: 25px;
                }
                p{
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: -0.28px;
                }
            }
            &:nth-child(1){
                img[alt="yellowMoney"]{
                    width: 136px;
                    top: 1px;
                    left: 1px;
                }
            }
            &:nth-child(2){
                img[alt="globe"]{
                    width: 92px;
                    top: 46px;
                    left: -10px;
                }
                img[alt="sattelite"]{
                    width: 100px;
                    top: -44px;
                    left: 38px;
                }
                img[alt="moneyPaper1"]{
                    width: 87px;
                    top: 10px;
                    left: 66px;
                }
                img[alt="moneyPaper2"]{
                    width: 87px;
                    top: -13px;
                    left: -19px;
                }
            }
            &:nth-child(3) &__right{
                //width: calc(100% - 85px);
            }
            &:nth-child(3){
                border: 2px solid #787878;
                background: $colorWhite;
                @media (max-width: 1440px){
                    order:5;
                }
            }
            &:nth-child(3) .text, &:last-child .text{
                position: absolute;
                top:0;
                right: 0;
                border: 2px solid #787878;
                border-bottom-left-radius: 30px;
                height: 80px;
                width: 80px;
                border-top:none;
                border-right:none;
                box-shadow: 4px -2px 0px 0px $colorWhite;
                background-color: $colorWhite;
                span{
                    position:absolute;
                    top:-20px;
                    right:-20px;
                    height: 80px;
                    width: 80px;
                    border-radius: 15px;
                    background:linear-gradient(124deg, #5B5B5B 20.22%, #050505 89%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 36px;
                    font-family: 'Krona one', sans-serif;
                }
                &:before{
                    content: "";
                    position: absolute;
                    height: 35px;
                    width: 60px;
                    box-shadow: 22px -16px 0 0 #fff;
                    border-top-right-radius: 15px;
                    left: -60px;
                    border: 2px solid #787878;
                    top: -1px;
                    border-left: none;
                    border-bottom: none;
                }
                &:after{
                    content: "";
                    position: absolute;
                    height: 35px;
                    width: 30px;
                    box-shadow: 6px -16px 0 0 #fff;
                    border-top-right-radius: 15px;
                    right:0;
                    top: 79px;
                    border-top: 2px solid #787878;
                }
            }
            &:nth-child(3){
                .text span{
                    background:$colorWhite;
                    border: 2px solid #787878;
                }
                h3{
                    padding-right: 67px;
                }
            }
            &:nth-child(4){
                img[alt="spin"]{
                    width: 70px;
                    top: -20px;
                    left: 60px;
                }
                img[alt="moneyPaper1"]{
                    width: 112px;
                    top: 31px;
                    left: -35px;
                }
                img[alt="moneyPaper2"]{
                    width: 83px;
                    top: 13px;
                    left: 76px;
                }
                img[alt="moneyPaper3"]{
                    width: 70px;
                    top: 91px;
                    left: 70px;
                }
            }
            &:nth-child(5){
                img[alt="carton"]{
                    width: 130px;
                    top: 0px;
                    left: -18px;
                }
            }
            &:last-child{
                background: linear-gradient(143deg, #525252 5.23%, #1B1B1B 64.67%);
                .text span{
                    color:$colorWhite;
                }
                .infos__item__right *{
                    color:$colorWhite;
                }
                h3{
                    padding-right: 67px;
                }
            }
        }
    }
}