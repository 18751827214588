@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.notfound{
    height: 100vh;
    &__top{
        padding-top: 100px;
        padding-bottom: 100px;
        @media (max-width: 2410px){
            padding-top: 25px;
            padding-bottom: 25px;
        }
        &__content{
            &:before{
                content:"";
                position: absolute;
                width: 70px;
                height: 70px;
                border-radius:50%;
                background: linear-gradient(154deg, #C1FFF4 15.15%, #006452 82.82%);
                filter: blur(8.5px);
                top: 0;
                left: calc(30% - 215px);

            }
            &:after{
                content:"";
                position: absolute;
                width: 40px;
                height: 40px;
                border-radius:50%;
                background: linear-gradient(154deg, #C1FFF4 15.15%, #006452 82.82%);
                filter: blur(4.5px);
                left: 61%;
                bottom: -52px;

            }

        }
        &__card{
            max-width: 438px;
            padding:40px 50px;
            border-radius: 30px;
            background: linear-gradient(180deg, #666 1.74%, #000 154.1%);
            filter: drop-shadow(5px 7px 17px rgba(0, 0, 0, 0.17));
            left:50%;
            @media (max-width: 2410px){
                padding:20px;
                max-width: 310px;
            }
            @media (max-width: 991px){
                left:inherit;
                margin:0 auto;
            }
            p{
                font-size: 14px;
                color:$colorWhite;
                line-height: 24px;
                span{
                    font-size: 14px;
                    line-height: 24px;
                }
                &:nth-child(1) span{
                    color:blue;
                }
                &:nth-child(2){
                    margin-left: 20px;
                    span.red{
                        color:$colorRed;
                    }
                    span.green{
                        color:$colorSuccess;
                    }
                }
            }
        }
    }
    &__bottom{
        &:before{
            content:"";
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, #666 1.28%, #000 113.65%);
            filter: drop-shadow(5px 7px 17px rgba(0, 0, 0, 0.17));
        }
        height: calc(100vh - 352px);
        @media (max-width: 2410px){
            height: calc(100vh - 162px);
        }
        @media (max-width: 1800px){
            z-index: -1;
        }
        &__content{
            max-width: 720px;
            margin: 0 auto 10px auto;
            padding-top: 60px;
            text-align: center;
            &:before{
                content:"";
                position: absolute;
                width: 35px;
                height: 35px;
                border-radius:50%;
                background: linear-gradient(154deg, #C1FFF4 15.15%, #006452 82.82%);
                filter: blur(2.5px);
                bottom:-80px;
                left: 50%;
                transform: translateX(-50%);

            }
            h2{
                margin-bottom: 50px;
                text-shadow: 5px 7px 17px rgba(0, 0, 0, 0.17);
                font-family: 'Retro Boldy', sans-serif;
                background: linear-gradient(180deg, #FFF -10.44%, #AAF0FF 103.63%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 58px;
                line-height: 58px;
                @media (max-width: 2410px){
                    font-size: 35px;
                    line-height: 35px;
                    margin-bottom: 25px;
                }
            }
            p{
                margin-bottom: 25px;
                color:$colorWhite;
                font-size: 18px;
                line-height: 28px;
                a{
                    color:$colorGreen;
                    font-size: 18px;
                    line-height: 28px;
                }
            }
            img{
                z-index: -1;
                position: absolute;
                &[alt="loupe_img"]{
                    width: 300px;
                    top: -210px;
                    left: 140px;
                    @media (max-width: 1800px){
                        width: 100px;
                        top:-25px;
                    }
                }
                &[alt="alert_img"]{
                    width: 200px;
                    top: -138px;
                    right: 193px;
                    @media (max-width: 1800px){
                        width: 100px;
                        top:-25px;
                    }
                }
            }
        }
    }
}