@mixin flex(){
  display: flex;
  display: -webkit-flex;
}

@mixin flexCenterVertical($align:'flex-start'){
  align-items: $align;
}

@mixin flexCenterHorizontal($align:'flex-start'){
  justify-content: $align;
}

@mixin flexWrap($wrap:'initial'){
  flex-wrap:$wrap;
}