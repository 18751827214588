@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.contact{
    &__content-p{
        padding-top: 100px;
        padding-bottom: 100px;
        @media (max-width: 1200px){
            grid-template-columns: repeat(1, 1fr);
        }
    }
    &__right-infos{
        &__top{
            background: linear-gradient(139deg, #FAFAFA 19.48%, #D9E9ED 83.69%);
            padding:50px;
            border-radius: 20px;
            margin-bottom: 50px;
            &__list{
                display:grid;
                grid-template-columns: repeat(2, 1fr);
                gap:30px;
                margin-top: 20px;
                margin-bottom: 40px;
            }
            &__item{
                span{
                    line-height: 14px;
                    letter-spacing: -0.28px;
                }
            }
            &__img{
                width: 80px;
                height: 80px;
                border-radius: 50%;
                margin-bottom: 20px;
                &:before{
                    content:"";
                    position: absolute;
                    height: 20px;
                    width: 20px;
                    border-radius: 50%;
                    background:linear-gradient(180deg, #D8D8D8 0%, #848484 100%);
                    z-index: 10;
                    bottom:0;
                    right:0;
                }
                &.active:before{
                    background: linear-gradient(151deg, #04C8A5 16.25%, #009B7F 88.84%);
                }
                img{
                    width:100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &__mails{
                margin-top: 20px;
                a{
                    display: block;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 36px;
                    letter-spacing: -0.36px;
                    text-decoration: underline !important;
                    color:$colorGreen;
                }
            }
            @media (max-width: 767px){
                padding:20px 15px;
            }
        }
        &__bottom{
            &__rs{ 
                @include flex();
                @include flexWrap($wrap:nowrap);
                gap:20px;
                margin-top: 40px;
                a{
                    display: block;
                    height: 80px;
                    width: 80px;
                    border-radius: 50%;
                    border:2px solid $colorBlack;
                    @include flex();
                    @include flexCenterVertical($align:center);
                    @include flexCenterHorizontal($align:center);
                    transition: .3s;
                    i{
                        background-color:$colorBlack;
                        transition: .3s;
                    }
                    &:hover{
                        background-color: $colorBlack;
                        i{
                            background-color: $colorWhite;
                        }
                    }
                }
            }
        }
    }
}