@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.auth-register{
    width: 960px;
    .form-bottom{
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        gap:30px;
        .form-radio{
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            gap:15px;
            p{
                width: calc(100% - 40px);
                margin-top: 0;
            }
        }
        @media (max-width: 767px){
            flex-direction: column;
            align-items: flex-start;
            button{
                order: 2;
            }
        }
    }
}