@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixins";

.faq{
    &__item{
        display: block;
        h3{
            padding-left: 35px;
            transition: .3s;
        }
        &:hover span{
            transform: translate(-50%, -50%) rotate(120deg)  scale(.6);
        }
        &:hover h3{
            color: $colorGreen;
        }
    }
    &__img{
        height: 450px;
        overflow: hidden;
        border-radius:15px;
        margin-bottom: 20px;
        &:before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: #000000bb;
        }
        span{
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%, -50%);
            transition: .3s;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}