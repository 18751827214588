*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  position: relative;
  /*z-index: 1;*/
}

img,
svg {
  max-width: 100%;
  height: auto;
}

ul,
ol,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

nav {
  background-color: transparent;
  box-shadow: none;
}

a {
  text-decoration: none !important;
}

mark {
  background-color: transparent;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

input[type="radio"]{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  -webkit-appearance: none;
  appearance: none;
  background-color: $colorWhite;
  cursor: pointer;
  border: 2px solid $colorGreen;
  &.active:before{
      content:"";
      position: absolute;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      top:50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $colorGreen;
  }
}

button {
  border: none;
  display: inline-block;
  background-color: transparent;
  transition: background-color .3s linear;
  cursor: pointer;
}

/*button:hover>i[class*="icon-"] {
  background-color: #f1902c;
}*/

table {
  width: 100%;
  border-collapse: collapse;
}