@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.banner{
    &__right-bonus{
        @include flex();
        @include flexCenterHorizontal($align:flex-end);
        @media (max-width: 1200px){
            @include flexCenterHorizontal($align:flex-start);
        }
        &:before{
            content: '';
            position: absolute;
            top:50px;
            left:0;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            background: linear-gradient(154deg, #C1FFF4 15.15%, #006452 82.82%);
            filter: blur(5px);
        }
        &__box{
            height: 366px;
            border-radius: 23px;
            background: linear-gradient(142deg, #FAFAFA 18.2%, #D9E9ED 78.19%);
            width: 1000px;
            &:after{
                content: '';
                position: absolute;
                top:-25px;
                right:80px;
                height: 52px;
                width: 52px;
                border-radius: 50%;
                background: linear-gradient(154deg, #C1FFF4 15.15%, #006452 82.82%);
                filter: blur(10px);
            }
            &:before{
                content: '';
                position: absolute;
                bottom:70px;
                left:-25px;
                height: 50px;
                width: 50px;
                border-radius: 50%;
                background: linear-gradient(154deg, #C1FFF4 15.15%, #006452 82.82%);
                filter: blur(7.5px);
            }
            
            &__img{
                max-width: 320px;
                margin-right: auto;
                margin-left: auto;
                &:before{
                    content:"";
                    position: absolute;
                    height: 300px;
                    width: 320px;
                    border-radius: 50%;
                    background: linear-gradient(151deg, #04C8A5 16.25%, #009B7F 88.84%);
                    left:50%;
                    top:50%;
                    transform: translate(-50%, -50%);
                }
                &:after{
                    content: '';
                    position: absolute;
                    top:-45px;
                    left:-145px;
                    height: 90px;
                    width: 90px;
                    border-radius: 50%;
                    background: linear-gradient(154deg, #C1FFF4 15.15%, #006452 82.82%);
                    filter: blur(5px);
                }
                img{
                    top:-20px;
                }
            }
        }
    }
}

.bonus{

    &__content {

    }
    &__tabs{
        .react-tabs__tab-list{
            @include flex();
            @include flexWrap($wrap:nowrap);
            @include flexCenterHorizontal($align:space-between);
            gap:30px;
            @media (max-width: 767px){
                overflow-x: scroll;
                @include scrollBar($direction:'x');
            }
            li{
                cursor:pointer;
                padding: 18px 10px;
                text-align: center;
                width: 100%;
                color:$colorWhite;
                background: linear-gradient(149deg, #525252 -4.02%, #1B1B1B 49.94%);
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                font-size: 28px;
                font-family: 'Krona One', sans-serif;
                line-height: 60px;
                letter-spacing: -0.72px;
                border: 2px solid #787878;
                border-bottom: none;
                white-space: nowrap;
                @media (max-width: 1440px){
                    font-size: 20px;
                    line-height: 24px;
                }
                &:focus, &:visited{
                    outline: none;
                }
                &.react-tabs__tab--selected{
                    color:$colorBlack;
                    background: none;
                    &:before{
                        content: "";
                        position: absolute;
                        left:0;
                        bottom:-7px;
                        height: 4px;
                        width: 100%;
                        background-color: $colorWhite;
                    }
                }
            }

        }
        .react-tabs__tab-panel{
            display: none;
            background-color: $colorWhite;
            &.react-tabs__tab-panel--selected{
                display: block;
                padding:30px;
                border: 2px solid #787878;
                border-bottom-right-radius: 20px;
                border-bottom-left-radius: 20px;
            }
        }
        &__box{
            display:grid;
            grid-template-columns: repeat(3, 1fr);
            gap:30px;
            @media (max-width: 991px){
                grid-template-columns: repeat(1, 1fr);
            }
            &__item{
                h3{
                    font-size: 24px;
                    line-height: 36px;
                    letter-spacing: -0.48px;
                    margin-bottom: 18px;
                }
                ul{
                    margin-bottom: 25px;
                    li{
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: -0.32px;
                        margin-bottom: 15px;
                        padding-left: 15px;
                        &:before{
                            content: "";
                            position: absolute;
                            top:8px;
                            left:0;
                            height: 9px;
                            width: 9px;
                            background: #00A385;
                            border-radius:50%;
                        }
                    }
                }
                p{
                    margin-bottom: 20px;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.32px;
                    span{
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: -0.32px;
                    }
                }
            }
        }
    }
    &__actions{
        padding: 50px 30px 30px 30px;
        background: #009B7F;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        top: -20px;
        z-index: -1;
    }
}