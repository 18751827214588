@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.banner{
    &__content{
        &-faq{
            @media (max-width: 1600px) {
                flex-wrap: wrap;
            }
        }
    }
    &__left{

        &__faq{

            @media (max-width: 1600px) {
                width:100%;
            }
            h2{
                margin-bottom: 40px;
                & + p{
                    max-width: 360px;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.32px;
                }
            }
            &__form{
                background: linear-gradient(143deg, #FAFAFA 33.64%, #D9E9ED 72.04%);
                padding:20px;
                margin-top: 40px;
                margin-bottom: 40px;
                overflow: hidden;
                border-radius: 20px;
                h3{
                    max-width: 240px;
                }
                input.key_word{
                    border:2px solid #787878;
                    width: 100%;
                    height: 42px;
                    border-radius: 10px;
                    padding-left: 15px; 
                    margin-top:15px;
                    margin-bottom:10px;
                }
                &__box{
                    @include flex();
                    @include flexCenterVertical($align:center);
                    gap:30px;
                    margin-top: 15px;
                    margin-bottom: 15px;
                    @media (max-width: 767px){
                        gap:10px;
                    }
                    p{
                        width: 130px;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: -0.24px;
                        margin-bottom: 0;
                    }
                    &__field{
                        @include flex();
                        @include flexCenterVertical($align:center);
                        gap:15px;
                        input[type="radio"]{
                            //width: 40px;
                        }
                        @media (max-width: 767px){
                            gap:5px;
                            input[type="radio"]{
                                //width: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    &__right{
        &__faq{
            @media (max-width: 1600px) {
                width:100%;
            }
            &__media{
                display: block;
                overflow: hidden;
                height: 500px;
                border-radius: 20px;
                & > img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                span.play-icon{
                    position:absolute;
                    top:50%;
                    left:50%;
                    transform: translate(-50%, -50%);
                    transition: .4s;
                }
                &:before{
                    content: "";
                    position: absolute;
                    top:0;
                    left:0;
                    height: 100%;
                    width: 100%;
                    background-color: #000000bb;
                }
                &:hover span{
                    transform: translate(-50%, -50%) rotate(120deg) scale(.6);
                }
            }
            &__tabs{
                margin-top:40px;
                .react-tabs{
                    @include flex();
                    @include flexWrap($wrap:nowrap);
                    @media (max-width: 991px){
                        flex-direction: column;
                    }
                    &__tab-list{
                        width: 320px;
                        li{
                            padding-top: 25px;
                            padding-bottom: 25px;
                            cursor: pointer;
                            padding-left: 25px;
                            font-size: 18px;
                            line-height: 20px;
                            letter-spacing: -0.36px;
                            & + li{
                                margin-top: 10px;
                            }
                            &:focus, &:visited{
                                outline:none;
                            }
                            &.react-tabs__tab--selected{
                                font-weight: 600;
                                &:before{
                                    content:"";
                                    position:absolute;
                                    top:0;
                                    left:0;
                                    width: 6px;
                                    height: 100%;
                                    background: linear-gradient(151deg, #04C8A5 16.53%, #009B7F 90.38%);
                                }
                            }
                        }
                        @media (max-width: 991px){
                            width: 100%;
                            @include flex();
                            align-items: center;
                            gap:10px;
                            overflow-x: auto;
                            @include scrollBar($direction:'x');
                            li{
                                padding-left: 10px;
                                padding-right: 10px;
                                white-space: nowrap;
                                &.react-tabs__tab--selected{
                                    &:before{
                                        content:"";
                                        position:absolute;
                                        top:inherit;
                                        bottom:0;
                                        left:0;
                                        width: 100%;
                                        height: 6px;
                                        background: linear-gradient(151deg, #04C8A5 16.53%, #009B7F 90.38%);
                                    }
                                }
                                & + li {
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                    &__tab-panel{
                        width: calc(100% - 320px);
                        display: none !important;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 30px;
                        border-left: 2px solid #DFDFDF;
                        padding-left: 30px;
                        @media (max-width: 991px){
                            width: 100%;
                            padding-left:0;
                            grid-template-columns: repeat(1, 1fr);
                            border-left: none;
                            margin-top:20px;
                            gap:15px;
                        }
                        p{
                            line-height: 20px;
                            letter-spacing: -0.28px;
                        }
                        div{
                            height: 300px;
                            background-color: #D9D9D9;
                        }
                        &.react-tabs__tab-panel--selected{
                            display:grid !important;

                        }
                    }
                }

            }
        }
    }
}

.faq{
    &__content{
        padding-bottom: 20px;
        h2{
            max-width: 640px;
            margin-bottom: 50px;
        }
    }
    &__card{

        @media (max-width: 1044px) {
            button{
                font-size: 18px;
                white-space: nowrap;
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
    &__list{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap:40px;
        margin-bottom: 50px;
        @media (max-width: 1044px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}