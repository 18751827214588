@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';
.footer{
    &__center{
        margin-bottom: -145px;
        max-width: max-content;
        margin-right: auto;
        margin-left: auto;
        @media (max-width: 962px) {
            padding-top: 155px;
            margin-right: 15px;
            margin-left:15px;
        }
        & > span{
            text-align: center;
            margin:0 auto;
            display: block;
            width: min-content;
            white-space: nowrap;
            border: 2px solid #787878;;
            border-bottom: none;
            border-radius: 23px 23px 0 0;
            padding: 12px 40px;
            font-family: "Krona One", sans-serif;
            font-size: 12px;
            color: $colorPrimaryBack;
        }
        &__card{
            max-width: 960px;
            //margin: 0 auto;
            background: linear-gradient(181deg, $colorSecondaryBg7 -12.05%, $colorSecondaryBg5 47.5%, $colorSecondaryBg6 98.96%);
            padding:35px;
            border-radius: 10px;
            filter: drop-shadow(5px 7px 17px rgba(0, 0, 0, 0.17));
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 70px;
            *{
                color:$colorWhite;
            }
            &__left{
                strong{
                    font-size: 18px;
                    font-weight: 600;
                }
                p{
                    font-size: 14px;
                    line-height: 24px;
                }
                .footer-form{
                    margin-top: 25px;
                    input{
                        display: block;
                        width: 320px;
                        border-radius:6px;
                        height: 42px;
                        border:none;
                        padding-left:20px;
                        color: $colorPrimaryBack;
                        margin-bottom:10px;
                    }
                    button{
                        text-align: center;
                        border:2px solid $colorWhite;
                        border-radius:6px;
                        height: 42px;
                        line-height: 42px;
                        padding-left:55px;
                        padding-right: 55px;
                        
                    }
                }
            }
            &__right{
                @include flex();
                gap: 20px;
                .footer-rs__list{
                    a{
                        display:block;
                        height: 40px;
                        width: 40px;
                        border-radius:50%;
                        border: 1px solid $colorWhite;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: .5s;
                        i{
                            background-color:$colorWhite;
                            transition: .5s;
                        }
                        & + a{
                            margin-top:15px;
                            
                        }
                        &:hover{
                            background-color:$colorWhite;
                            i{
                                background-color: $colorGreen;
                            }
                        }
                    }
                }
                .footer-rs__text {
                    padding-right: 80px;
                    strong{
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                    }
                    p{
                        font-size: 14px;
                        line-height: 24px;
                        margin-top:15px;
                    }
                }
            }
            @media (max-width: 845px){
                grid-template-columns:100%;
                gap:25px;
                &__left{
                    order:2;
                    text-align: center;
                    input{
                        width: 100% !important;
                    }
                }
                &__right{
                    order:1;
                    flex-direction: column-reverse;
                    .footer-rs__list{
                        @include flex();
                        @include flexWrap($wrap:nowrap);
                        @include flexCenterHorizontal($align:center);
                        gap:15px;
                        a + a{
                            margin-top: 0;
                        }
                    }
                    .footer-rs__text{
                        padding-right: 0;
                        text-align: center;
                    }
                }
            }
        }
    }
    &__bottom{
        min-height: 240px;
        background: linear-gradient(181deg, $colorSecondaryBg4 8.25%, $colorSecondaryBg5 57.01%, $colorSecondaryBg6 99.15%);
        z-index: -1;
        &__top{
            @include flex();
            @include flexCenterVertical($align:center);
            @include flexCenterHorizontal($align:space-between);
            padding-top: 100px;
            @media (max-width: 1695px) {
                padding-top: 155px;
            }
            @media (max-width: 767px){
                @include flexCenterHorizontal($align:center);
            }
        }
        &__left *{
            color: $colorWhite;
        }
        &__bottom{
            text-align: center;
            margin-top: 32px;
            padding-bottom: 15px;
            p{
                color: $colorWhite;
            }
        }
        &__left{
            color:$colorWhite;
            span{
                display: block;
            }
            strong{
                font-family: "Krona One", sans-serif;
                font-size: 28px;
                line-height:38px;
            }
            @media (max-width: 767px){
                text-align: center;
            }
        }
        .footer-menu{
            &-action{
                display: flex;
                @include flexWrap($wrap:nowrap);
                gap:20px;
                &__auth{
                    display: inline-block;
                    border-radius: 8px;
                    border: 2px solid $colorWhite;
                    white-space: nowrap;
                    text-align: center;
                    padding:0 10px;
                    @include flex();
                    @include flexCenterVertical($align:center);
                    transition: .5s;
                    color: $colorWhite;
                    &.login{
                        @include flexCenterHorizontal();
                        gap:5px;
                        padding-right: 25px;
                        //border-radius: 23px;
                        img{
                            width: 35px;
                        }
                    }
                    &.register{
                        @include flexCenterHorizontal();
                        padding:0 43px;
                    }
                    &:hover{
                        background-color: $colorWhite;
                        color: $colorGreen;
                    }
                    @media (max-width: 1250px){
                        //display: none;
                    }
                }
                @media (max-width: 1250px){
                    //margin-top: 15px;
                    //@include flex();
                }
            }
            @media (max-width: 767px){
                display: none;
            }
        }
    }
}